// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-modules-article-index-tsx": () => import("./../../../src/modules/article/index.tsx" /* webpackChunkName: "component---src-modules-article-index-tsx" */),
  "component---src-modules-design-de-solucoes-index-tsx": () => import("./../../../src/modules/design-de-solucoes/index.tsx" /* webpackChunkName: "component---src-modules-design-de-solucoes-index-tsx" */),
  "component---src-modules-dpc-index-tsx": () => import("./../../../src/modules/dpc/index.tsx" /* webpackChunkName: "component---src-modules-dpc-index-tsx" */),
  "component---src-modules-ene-academy-index-tsx": () => import("./../../../src/modules/ene-academy/index.tsx" /* webpackChunkName: "component---src-modules-ene-academy-index-tsx" */),
  "component---src-modules-politicas-de-privacidade-index-tsx": () => import("./../../../src/modules/politicas-de-privacidade/index.tsx" /* webpackChunkName: "component---src-modules-politicas-de-privacidade-index-tsx" */),
  "component---src-modules-qualidade-de-software-index-tsx": () => import("./../../../src/modules/qualidade-de-software/index.tsx" /* webpackChunkName: "component---src-modules-qualidade-de-software-index-tsx" */),
  "component---src-modules-termos-de-uso-index-tsx": () => import("./../../../src/modules/termos-de-uso/index.tsx" /* webpackChunkName: "component---src-modules-termos-de-uso-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-busca-tsx": () => import("./../../../src/pages/blog/busca.tsx" /* webpackChunkName: "component---src-pages-blog-busca-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

